
import Header from "../components/Header/Header.vue";

import PageLejedata from "./components/PageLejedata.vue";

import PageNoAccess from "~/components/rent-levels/PageNoAccess.vue";

import Permissions from "~/graphql/Authentication/Permissions.gql";

export default {
  title() {
    return "lejedata";
  },

  components: {
    Header,
    PageLejedata,
    PageNoAccess,
  },

  apollo: {
    me: {
      query: Permissions,
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.me.loading;
    },

    hasAccess: function () {
      const RENTAL_PERMISSION_READ = "RENT_CHECK:READ";
      const RENTAL_PERMISSION_WRITE = "RENT_CHECK:WRITE";

      return this.me?.permissions?.some((p) => p.indexOf(RENTAL_PERMISSION_READ) != -1 || p.indexOf(RENTAL_PERMISSION_WRITE) != -1);
    },
  },
};
